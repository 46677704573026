<template>
  <el-dialog
    :visible.sync="value"
    :title="editTitle"
    destroy-on-close
    :before-close="onBeforeClose"
    width="500px"
    @closed="onClosed"
    @open="onOpen"
  >
    <div v-loading="loading">
      <el-form ref="elForm" label-position="top" :model="form" :rules="rules">
        <el-form-item label="客服电话" prop="mobile">
          <el-input v-model="form.mobile" maxlength="11" clearable />
        </el-form-item>
        <el-form-item label="服务时间" prop="time">
          <el-time-picker
            v-model="form.time"
            style="width: 100%"
            is-range
            format="HH-mm"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
      </el-form>
      <div class="_btn-list">
        <el-button class="_btn-item" size="large" @click="onBeforeClose">取消</el-button>
        <el-button class="_btn-item" size="large" type="primary" @click="addCustomerService">
          确定
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dayjs from "dayjs";

import { inputRequired, minLength, selectRequired } from "@/utils/rules";

export default {
  name: "CustomerDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        mobile: "",
        time: [new Date("2023-06-15 09:00:00"), new Date("2023-06-15 18:00:00")],
      },
      rules: {
        mobile: [inputRequired("手机号"), minLength()],
        time: [selectRequired("服务时间")],
      },
      loading: false,
    };
  },
  computed: {
    editTitle() {
      return this.isEdit ? "新增客服电话" : "修改客服电话";
    },
    isEdit() {
      return !!this.data.id;
    },
  },
  methods: {
    onOpen() {
      this.form = {
        ...this.data,
      };
    },
    onBeforeClose() {
      this.$emit("input", false);
    },
    onClosed() {
      this.$emit("clear");
    },
    addCustomerService() {
      this.$refs.elForm.validate((valid) => {
        if (!valid) return;

        const { id, mobile, time } = this.form;
        this.loading = true;
        this.$Axios
          ._post({
            url: this.$Config.apiUrl[this.isEdit ? "servicePhoneUpdate" : "servicePhoneAdd"],
            data: (() => {
              const params = { mobile };
              const [start, end] = time;
              if (this.isEdit) {
                params.id = id;
              }
              return {
                ...params,
                beginTime: dayjs(start).format("HH:mm"),
                endTime: dayjs(end).format("HH:mm"),
              };
            })(),
          })
          .then((res) => {
            if (res.data === true) {
              this.$message.success("操作成功");
              this.onBeforeClose();
              this.$emit("success");
            } else {
              this.$message.error(res.message);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._btn-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
</style>
