<template>
  <div v-loading="loading" class="_customer-service">
    <div v-if="isAddCustomer">
      <el-button icon="el-icon-plus" type="primary" @click="custiomerDialog.toggle = true">
        客服电话
      </el-button>
      <div style="margin-top: 20px">~若您未设置客服电话，将给用户展示平台客服电话~</div>
    </div>
    <div v-else class="_customer-info">
      <div class="_customer-info-warpper">
        <div>
          <span>客服电话：</span>
          <div>{{ customerServiceInfo.mobile }}</div>
        </div>
        <div>
          <span>服务时间：</span>
          <div>
            <span>{{ times.startTime }}</span>
            <span>-</span>
            <span>{{ times.endTime }}</span>
          </div>
        </div>
      </div>
      <el-button type="primary" icon="el-icon-edit" @click="onEdit">修改</el-button>
    </div>

    <customer-dialog
      v-model="custiomerDialog.toggle"
      :data="custiomerDialog.data"
      @clear="customerServiceInfo.data = {}"
      @success="getServicePhone"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";

import CustomerDialog from "./customerDialog.vue";

export default {
  name: "CustomerService",
  components: {
    CustomerDialog,
  },
  data() {
    return {
      customerServiceInfo: {
        mobile: "",
        time: [],
      },
      custiomerDialog: {
        toggle: false,
        data: {},
      },
      loading: false,
    };
  },
  computed: {
    isAddCustomer() {
      return this.customerServiceInfo.mobile === "";
    },
    times() {
      const [start, end] = this.customerServiceInfo.time;
      return {
        startTime: dayjs(new Date(start || new Date())).format("HH:mm"),
        endTime: dayjs(new Date(end || new Date())).format("HH:mm"),
      };
    },
  },
  methods: {
    onEdit() {
      this.custiomerDialog.data = JSON.parse(JSON.stringify(this.customerServiceInfo));
      this.custiomerDialog.toggle = true;
    },
    getServicePhone() {
      this.loading = true;
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.servicePhoneQuery,
        })
        .then((res) => {
          const { mobile, id, beginTime, endTime } = res.data || {};
          this.customerServiceInfo = {
            mobile: mobile || "",
            id: id || "",
            time: [
              new Date(`2023-06-19 ${beginTime || "00:00"}`),
              new Date(`2023-06-19 ${endTime || "23:59"}`),
            ],
          };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
._customer-service {
  margin-top: 12px;
  padding: 24px;
  background-color: #fff;
}

._customer-info-warpper {
  display: flex;
  margin-bottom: 20px;
  & > div {
    display: flex;
    margin-right: 40px;
  }
}
</style>
