<template>
  <div class="bank-account-content a-flex-rfsc a-flex-wrap">
    <div v-for="(item, index) in bankList" :key="index" class="bank-info mgr11">
      <div class="bank-info-head a-flex-rfsc">
        <img
          src="../../../assets/icon/card-icon.png"
          style="width: 14px; height: 14px; margin-right: 6px"
          alt=""
        />
        <el-tooltip effect="dark" :content="item.bankBranch" placement="top">
          <span class="a-fs-14 a-c-normal bankName">{{ item.bankBranch }}</span>
        </el-tooltip>
      </div>
      <div class="a-fs-20 a-mlr-17 pdtb11 a-fw-700">
        {{ item.accountNumber | initBankNumber }}
      </div>
      <div class="a-fs-12 a-mlr-17 a-c-second">{{ item.accountName }}</div>
      <div class="a-flex-rsbc a-mlr-17" style="padding: 6px 0 8px">
        <span v-if="item.authStatus == 0" class="ffa900 a-fs-12">* 该银行卡需要认证后可提现</span>
        <span v-else></span>
        <div class="a-flex-rfsc">
          <div
            v-if="item.authStatus == 0"
            class="a-flex-rcc bank-btn1"
            @click="$refs['bankqrCode'].qrVal = item.checkUrl"
          >
            <span>去认证</span>
          </div>
          <div
            v-if="item.bankStatus == 1"
            class="a-flex-rcc bank-btn2 a-ml-16"
            @click="unbindBankAccount(item)"
          >
            <span>解绑</span>
          </div>
        </div>
      </div>
    </div>
    <div class="add-bank-content a-flex-rcc" @click="toAddCard">
      <i class="el-icon-plus a-fs-24 a-c-blue a-plr-7"></i>
      <div class="a-flex-cfsfs">
        <span class="a-fs-16 a-c-blue a-fw-700">添加银行卡</span>
        <span class="a-fs-14 a-c-second">添加并认证后可提现</span>
      </div>
    </div>
    <bank-authentication ref="bankqrCode"></bank-authentication>
  </div>
</template>

<script>
import BankAuthentication from "./bank-authentication.vue";
export default {
  filters: {
    initBankNumber(val) {
      return val.replace(/\s/g, "").replace(/(.{4})/g, "$1 ");
    },
  },
  components: { BankAuthentication },
  data() {
    return {
      bankList: [],
    };
  },
  mounted() {
    this.getBankAccountList();
  },
  methods: {
    // 获取银行账户列表
    getBankAccountList() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getBankAccountList,
          method: "get",
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.bankList = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    unbindBankAccount(datas) {
      this.$confirm("是否确认解绑该银行账户?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.unbindBankAccount,
            method: "get",
            params: {
              companyBankId: datas.id,
            },
          })
          .then((res) => {
            if (res.result.code == 0) {
              this.$message.success("操作成功");
              this.getBankAccountList();
            } else {
              this.$message.error(res.result.message);
            }
          });
      });
    },
    toAddCard() {
      this.$router.push({
        path: "/businessCenter/bank-card-add",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-account-content {
  background: #ffffff;
  border-radius: 3px;
  padding: 24px;
  margin-top: 11px;
  .bankName {
    width: 440px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
}
.bank-info {
  background: #ffffff;
  border-radius: 3px 3px 0px 0px;
  border: 1px solid #ebf0f5;
  width: 515px;
  margin-bottom: 11px;
}
.bank-info-head {
  background: #f2f8ff;
  border-radius: 3px 3px 0px 0px;
  padding: 9px 17px;
}
.pdtb11 {
  padding: 11px 0;
}
.ffa900 {
  color: #ffa900;
}
.bank-btn1 {
  width: 60px;
  height: 32px;
  padding: 4px 8px;
  background: #d9ebff;
  border-radius: 16px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #007aff;
  cursor: pointer;
}
.bank-btn2 {
  width: 60px;
  height: 32px;
  padding: 4px 8px;
  background: #f5f7fa;
  border: 1px solid #ebf0f5;
  border-radius: 16px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #606366;
  cursor: pointer;
}
.add-bank-content {
  height: 158px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px dashed #dadde0;
  cursor: pointer;
  width: 515px;
  margin-bottom: 11px;
}
.mgr11 {
  margin-right: 11px;
}
</style>
