import { render, staticRenderFns } from "./tradeAuth.vue?vue&type=template&id=179ea1aa&scoped=true"
import script from "./tradeAuth.vue?vue&type=script&lang=js"
export * from "./tradeAuth.vue?vue&type=script&lang=js"
import style0 from "./tradeAuth.vue?vue&type=style&index=0&id=179ea1aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179ea1aa",
  null
  
)

export default component.exports