<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-700">商户中心</span>
      <div class="a-flex-rcc">
        <!-- <el-button icon="el-icon-switch" type="primary" class="s-btn-add" >切换商户</el-button> -->
        <div class="s-btn-add switchBusiness a-flex-rcc font-point" @click="switchBusiness">
          <img
            src="../../assets/icon/switch-icon.png"
            style="width: 12px; height: 12px; margin-right: 6px"
            alt=""
          />
          <span>切换商户</span>
        </div>
      </div>
    </div>
    <div class="businessInfo">
      <div class="business-baseinfo">
        <div class="a-flex-rfsc">
          <img
            :src="
              businessInfo && businessInfo.logoPath
                ? businessInfo.logoPath
                : require('../../assets/icon/business-icon.png')
            "
            style="width: 34px; height: 34px; margin-right: 8px; border-radius: 50%"
            alt=""
          />
          <span class="a-c-master a-fs-16 a-fw-700">
            {{ businessInfo && businessInfo.name ? businessInfo.name : "" }}
          </span>
        </div>
        <div class="a-flex-rfsfs a-flex-wrap">
          <div class="a-flex-rfsfs mt21 mr80">
            <span class="title c79">主体类型</span>
            <span class="content a-c-master">
              {{ businessInfo && businessInfo.newTypeText ? businessInfo.newTypeText : "" }}
            </span>
          </div>
          <div class="a-flex-rfsfs mt21 mr80">
            <span class="title c79">商户简称</span>
            <span class="content a-c-master">
              {{ businessInfo && businessInfo.brand ? businessInfo.brand : "" }}
            </span>
          </div>
          <div class="a-flex-rfsfs mt21 mr80">
            <span class="title c79">商户全称</span>
            <span class="content a-c-master">
              {{ businessInfo && businessInfo.name ? businessInfo.name : "" }}
            </span>
          </div>
          <div class="a-flex-rfsfs mt21 mr80">
            <span class="title c79">所在地区</span>
            <span class="content a-c-master">
              {{ businessInfo && businessInfo.name ? businessInfo.province : "" }}
              {{ businessInfo && businessInfo.name ? businessInfo.city : "" }}
              {{ businessInfo && businessInfo.name ? businessInfo.district : "" }}
            </span>
          </div>
          <div class="a-flex-rfsfs mt21 mr80">
            <span class="title c79">法人姓名</span>
            <span class="content a-c-master">
              {{ businessInfo && businessInfo.repName ? businessInfo.repName : "" }}
            </span>
          </div>
          <div class="a-flex-rfsfs mt21 mr80">
            <span class="title c79">统一社会信用代码</span>
            <span class="content a-c-master">
              {{ businessInfo && businessInfo.creditCode ? businessInfo.creditCode : "" }}
            </span>
          </div>
        </div>
        <div class="business-baseinfo-item a-flex-rsbc">
          <div class="a-flex-cfsfs a-flex-1">
            <span class="a-c-master a-fs-14 a-pb-6">累计用户</span>
            <div class="a-flex-rfsfs">
              <span class="a-c-master a-fs-14 a-fw-700">
                {{ userIncrease && userIncrease.userNumHis ? userIncrease.userNumHis : 0 }}
              </span>
              <span class="a-c-blue a-fs-8 c-pl-6">
                本月+{{ userIncrease && userIncrease.userNumMonth ? userIncrease.userNumMonth : 0 }}
              </span>
            </div>
          </div>
          <div class="a-flex-cfsfs a-flex-1">
            <span class="a-c-master a-fs-14 a-pb-6">运行天数</span>
            <div class="a-flex-rfsfs">
              <span class="a-c-master a-fs-14 a-fw-700">
                {{ businessInfo && businessInfo.runDays ? businessInfo.runDays : 0 }}
              </span>
            </div>
          </div>
          <div class="a-flex-cfsfs a-flex-1">
            <span class="a-c-master a-fs-14 a-pb-6">自营站点</span>
            <div class="a-flex-rfsfs">
              <span class="a-c-master a-fs-14 a-fw-700">
                {{ userIncrease && userIncrease.stationNum ? userIncrease.stationNum : 0 }}
              </span>
              <span class="a-c-blue a-fs-8 c-pl-6">
                本月+{{
                  userIncrease && userIncrease.stationNumMonth ? userIncrease.stationNumMonth : 0
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs">
        <el-tabs v-model="tabAc" @tab-click="handleClick">
          <el-tab-pane label="账户余额" name="zhye">
            <business-account ref="zhye" :balance="businessInfo.balance"></business-account>
          </el-tab-pane>
          <el-tab-pane label="银行账户" name="yhzh">
            <bank-account ref="yhzh"></bank-account>
          </el-tab-pane>
          <el-tab-pane label="代理合同" name="dlht">
            <agency-contract ref="dlht"></agency-contract>
          </el-tab-pane>
          <el-tab-pane label="通联交易权限" name="jyqx">
            <trade-auth ref="jyqx"></trade-auth>
          </el-tab-pane>
          <el-tab-pane label="客服电话" name="kfdh">
            <customer-service ref="kfdh" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import AgencyContract from "./child/agency-contract.vue";
import BankAccount from "./child/bank-account.vue";
import BusinessAccount from "./child/business-account.vue";
import CustomerService from "./child/customerService.vue";
import TradeAuth from "./child/tradeAuth.vue";

export default {
  name: "BusinessCenter",

  components: {
    BusinessAccount,
    BankAccount,
    AgencyContract,
    TradeAuth,
    CustomerService,
  },
  data() {
    return {
      tabAc: "zhye",
      businessInfo: "", //商户基本信息
      userIncrease: "",
    };
  },
  created() {
    if (this.$route.query.tabs) {
      this.tabAc = this.$route.query.tabs;
    }
  },
  mounted() {
    this.getBusinessInfo();
    this.getUserIncrease();
  },
  methods: {
    // 获取公司信息
    getBusinessInfo() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getCompanyInfoNoCache,
          method: "get",
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.businessInfo = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 获取累计用户、本月新增用户、累计站点、本月新增站点
    getUserIncrease() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getMineStat,
          method: "get",
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.userIncrease = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    handleClick() {
      if (this.tabAc == "zhye") {
        this.getBusinessInfo();
        this.$refs["zhye"].getUnConfirmAmount();
        this.$refs["zhye"].handleClick();
      } else if (this.tabAc == "yhzh") {
        this.$refs["yhzh"].getBankAccountList();
      } else if (this.tabAc == "dlht") {
        this.$refs["dlht"].getAgencyDetail();
      } else if (this.tabAc == "jyqx") {
        this.$refs["jyqx"].getCompanyAccount();
        this.$refs["jyqx"].getUserAccountStatus();
      } else if (this.tabAc === "kfdh") {
        this.$refs["kfdh"].getServicePhone();
      }
    },
    switchBusiness() {
      this.$router.push({
        path: "/businessCenter/switch-business",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.business-baseinfo {
  background: #ffffff;
  border-radius: 3px;
  padding: 24px;
}
// /deep/ .el-icon-switch{
//     background: url('../../assets/icon/switch-icon.png') center no-repeat;
//     background-size: 100% 100%;
//     width: 12px;
//     height: 12px;
//     font-size: 12px;
// }
// /deep/ .el-icon-switch:before{
//     content: "替";
//     font-size: 12px;
//     visibility: hidden;
// }
.businessInfo {
  font-size: 14px;
  height: 100%;
  overflow-y: auto;
}
.mt64 {
  margin-top: 64px;
}
.mt21 {
  margin-top: 21px;
}
.mr80 {
  margin-right: 80px;
}
.c79 {
  color: #797979;
}
.title {
  width: 120px;
  margin-right: 16px;
}
.content {
  width: 300px;
}
.business-baseinfo-item {
  background: linear-gradient(90deg, #f5f7fa 0%, #fbfcfd 100%);
  border-radius: 3px;
  padding: 11px 17px;
  margin-top: 14px;
}
.a-fs-8 {
  font-size: 8px;
}
.c-pl-6 {
  padding-left: 6px;
}
.tabs {
  // background: #ffffff;
  // padding: 13px 17px 0
}
/deep/ .el-tabs__item {
  // color: #007aff;
  font-size: 14px;
}

/deep/ .el-tabs__item.is-active {
  color: #007aff;
  font-weight: bold;
}

/deep/ .el-tabs__nav-wrap::after {
  background: none !important;
}

/deep/ .el-tabs__header {
  padding-left: 24px;
  padding-top: 20px;
  margin-bottom: 2px;
  border-radius: 3px;
  background: #ffffff;
}
.switchBusiness {
  background: #007aff;
  border-radius: 3px;
  color: #ffffff;
  font-size: 10px !important;
}
</style>
